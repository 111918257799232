<template>
  <div class="pdf-doc">
    <div class="pdf-view" v-if="hasDetailsInfo">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="img-vessel">
                <img :src="hasVesselImg" alt="VESSEL" srcset="" />
              </div>
              <div class="title-vessel">
                <strong> {{ titleDataSheet }} </strong>
              </div>
              <div class="info-vessel">
                <div class="vessel">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{{ $t('label.type') }}: </strong
                          >{{ getDato(details.TpVesselName) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.country') }}: </strong
                          >{{ getDato(details.CountryName) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.IMO') }}: </strong>{{ getDato(details.Imo) }}
                        </td>
                        <td class="limit-field-30 p-0">
                          <table>
                            <tr>
                              <td>
                                <strong>MMSI: </strong
                                >{{ getDato(details.Mmsi) }}
                              </td>
                              <td>
                                <strong>CALL SIGN: </strong>
                                {{
                                  getDato(details.CallSign)
                                    ? getDato(details.CallSign)
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>LOA:</strong> {{ getDato(details.Loa) }} (MTR)
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.bowDraft') }}: </strong
                          >{{ getDato(details.DraftBow) }} (MTR)
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.summerDWT') }}: </strong
                          >{{ getDato(details.SummerDwt) }}
                        </td>
                        <td class="limit-field-30">
                          <strong>{{ $t('label.LWL') }}: </strong>{{ getDato(details.Lwl) }} (MTR)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t('label.sternDraft') }}: </strong
                          >{{ getDato(details.DraftStern) }} (MTR)
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.gross') }}: </strong>{{ NumberFormat(getDato(details.Gross), 2) }} (TON)
                        </td>
                        <td class="limit-field-20">
                          <strong>TEUS: </strong>{{ getDato(details.Teus) ? getDato(details.Teus) : "-" }}
                        </td>
                        <td class="limit-field-30">
                          <strong>{{ $t('label.fuelOilCapacity') }}: </strong
                          >{{ getDato(details.FuelOilCapacity) }} (M³)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="transversal-view">
                  <transversal-view
                    :vessel-id="getDato(details.VesselId)"
                    :VesselType="getDato(details.TpVesselId)"
                    pdf
                    :tooltip="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>{{ $t("label.page") }}: 1 {{ $t("label.of") }} {{ totalPages }}</p>
          </div>
        </section>
      </section>

      <!-- Paginas Plano Completo -->
      <section class="pdf" v-for="(n, i) in numberOfPages" :key="i">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="plan center-bays">
            <CRow class="flex-one-bays">
              <CCol sm="12">
                <div class="zoom-container zoom-center">
                  <div class="bay-container" :style="{ width: `${zoomByBays}%` }">
                    <div
                      class="bay-row"
                      :style="{
                        width: `calc( calc(100% / ${columnas}) - 10px )`,
                      }"
                      v-for="(bays, index) in pageList(n)"
                      :key="index"
                    >
                      <div
                        class="bay-col"
                        v-for="(bay, index) in bays"
                        :key="index"
                      >
                        <bay-container
                          v-if="bay != null"
                          :tooltip="false"
                          :headerSize="50"
                          :margin="0.5"
                          showStatus
                          :bayInfo="bay"
                          :positionsToDeck="getPositionsToDeck(bay)"
                          :positionsUnderDeck="getPositionsUnderDeck(bay)"
                          :colSize="generalCol"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>{{ $t("label.page") }}: 2 {{ $t("label.of") }} {{ totalPages }}</p>
          </div>
        </section>
      </section>

      <!-- Lista de Bays -->
      <section class="pdf" v-for="(bay, index) in unformatedBaysList" :key="index+'bayview'">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="plan">
            <CRow class="max-height">
              <CCol sm="12">
                <div class="zoom-container-bayview zoom-center-bayview">
                  <div
                    class="bay-view-container"
                    :style="{ width: `60%` }"
                  >
                    <div class="bay-view" v-if="bay">
                      <bay-container
                        bayView
                        showStatus
                        :maxSize="50"
                        :bayInfo="bay"
                        :numberSize="30"
                        :positionsToDeck="getPositionsToDeck(bay)"
                        :positionsUnderDeck="getPositionsUnderDeck(bay)"
                        :colSize="generalCol"
                        :header="false"
                      />
                    </div>
                  </div>
                  <div class="bay-info">
                    <div class="position-relative">
                      <ul>
                        <li>TEUS: {{ bay.Teus }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>BAY {{ bay.BayCode }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page") }}:
              {{ index + 3}}
              {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataStorage from "@/_helpers/localStorage";
import { NumberFormater } from '@/_helpers/funciones';
import BayMixin from "@/_mixins/bays";
import BayContainer from "@/pages/planificacion-estiba/archivos/bays/bay-container";
import TransversalView from '@/pages/buque/bays/transversal-view';

//Data
function data() {
  return {
   
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function NumberFormat(number, decimal) {
  if(decimal == 0){
    let format = new Intl.NumberFormat('de-DE').format(number)
    return format;
  }
  return NumberFormater.formatNumber(number, decimal);
}

function getDato(value) {
  if (!this.details) return "";

  return value ? value : "";
}
function formatNumber(number) {
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace('.', ',');

  return format.replace(/\d(?=(\d{3})+\,)/g, '$&.');
}
function pageList(currentPage) {
  var begin = (currentPage - 1) * this.numberPerPage;
  var end = begin + this.numberPerPage;

  return this.baysList.slice(begin, end);
}

//Computeds:
function hasDetailsInfo() {
  return this.details ? true : false;
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function hasVesselImg() {
  return `${this.$store.getters["connection/getBase"]}${
    this.vesselRoute ? this.vesselRoute.replace("Public/", "", null, "i") : ""
  }`;
}
function footerText() {
  return "";
}
function currentDateTime() {
  let today = new Date();
  let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
  let date =
    today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleFromCompany() {
  return this.user ? `${this.user.CompanyName} ${this.user.CompanyRif}` : "";
}
function companyLocation() {
  return this.user ? `${this.user.FiscalAddress}` : "";
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}
function titleDataSheet() {
  if (!this.details) return `${this.$t("label.vesselSheet")}: `;

  let name = this.details.VesselName,
    imo = this.details.Imo;

  return `${this.$t("label.vesselSheet")}: ${name} - ${imo}`;
}
function totalPages() {
  return 2+this.unformatedBaysList.length;
}
function numberOfPages() {
  return Math.ceil(this.baysList.length / this.numberPerPage);
}
function zoomByBays() {
  let filas = this.numberPerPage / this.columnas;

  if(filas == 1) {
    switch (this.columnas) {
      case 1:
        return 30;
      case 2:
        return 55;
      case 3:
        return 75;
      case 4:
        return 95;
      case 5:
      case 6:
      case 7:
      case 8:
        return 98;
      default:
        return 100;
    }
  }
  
  if(filas == 2){
    switch (this.columnas) {
      case 1:
        return 12;
      case 2:
        return 24;
      case 3:
        return 34;
      case 4:
        return 44;
      case 5:
        return 54;
      case 6:
        return 64;
      case 7:
        return 74;
      default:
        return 98;
    }
  }
}

export default {
  name: "vessel-plan-pdf",
  props: {
    details: {
      type: Object,
      default: null,
    },
    vesselRoute: {
      type: String,
      default: "",
    },
    baysList: {
      type: Array,
      default: () => [],
    },
    unformatedBaysList: {
      type: Array,
      default: () => [],
    },
    columnas: {
      type: Number,
      default: 8,
    },
    generalCol: {
      type: Number,
      default: 12,
    },
    numberPerPage: {
      type: Number,
      default: 3,
    },
  },
  mixins: [BayMixin],
  components: {
    BayContainer,
    TransversalView,
  },
  data,
  created,
  methods: {
    NumberFormat,
    getDato,
    formatNumber,
    pageList,
  },
  computed: {
    footerText,
    currentDateTime,
    titleFromCompany,
    companyLocation,
    hasLogo,
    hasVesselImg,
    loginName,
    hasDetailsInfo,
    titleDataSheet,
    totalPages,
    numberOfPages,
    zoomByBays,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {},
};
</script>

<style scoped src="../../planificacion-estiba/archivos/pdf/pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */

.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 650px;

  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;

  background-color: #a6a6a6;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .img-vessel {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-vessel img {
  height: 100%;
}
.info-content .title-vessel {
  width: 78%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 1.1rem;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  height: 75%;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.info-content .info-vessel .vessel > table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
}
.vessel > table,
.vessel > table td {
  border: 1px solid #000;
}
.vessel > table td {
  padding: 2px 5px;
  width: 25%;
}
.limit-field-20 {
  width: 20% !important;
}
.limit-field-30 {
  width: 30% !important;
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}

.info-content .info-vessel .transversal-view {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  overflow: hidden;
}
.np-border-view {
  border: none;
}

/* *************************************************** */
/* Bays */
/* *************************************************** */
.zoom-container {
  width: 100%;
  max-height: 670px;
  overflow: hidden;
}
.zoom-center {
  display: flex;
  justify-content: center;
}

/* Contenido de los Bays */
.bay-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.bay-row {
  position: relative;
  margin: 0 0 5px 5px;
  width: calc(calc(100% / 3) - 10px);
  padding: 10px 0;

  display: flex;
  flex-direction: column;
}

.bay-col {
  position: relative;
  width: 100%;
  flex: 1;
}
.bay-col:first-child {
  margin-bottom: 15px;
}
.plan.center-bays {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .plan .flex-one-bays {
    flex: 1;
  }

/* *************************************************** */
/* Bay View */
/* *************************************************** */
.zoom-container-bayview {
  width: 100%;
  height: 100%;
  max-height: 670px;
  overflow: hidden;
}
.zoom-center-bayview {
  display: flex;
  justify-content: center;
  align-items: center;
}
.max-height {
  height: 100%;
}

/* Bay View */
.bay-view-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.bay-view {
  margin: 10px 5px;
  width: 100%;
}
.no-border-view {
  border: none !important;
}

.bay-info {
  position: absolute;
  right: 1.5%;
  bottom: 10%;
  width: 220px;
  height: 85px;
  margin-left: auto;
  font-size: 1.05rem;
  font-weight: bold;
}
.bay-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>